<template>
  <div>없는 페이지입니다.</div>
</template>

<script>
export default {

}
</script>

<style>

</style>